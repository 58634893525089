/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

let routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/onde-encontrar',
		name: 'OndeEncontrar',
		component: () => import(/* webpackChunkName: "OndeEncontrar" */ '@views/OndeEncontrar/OndeEncontrar'),
	},
	{
		path: '/contato',
		name: 'Contato',
		component: () => import(/* webpackChunkName: "Contato" */ '@views/Contato/Contato'),
	},
	{
		path: '/trabalhe-conosco',
		name: 'Trabalhe',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/industrias',
		name: 'Industrias',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/preco-do-dia',
		name: 'Preço',
		component: () => import(/* webpackChunkName: "Preco" */ '@views/Preco/Preco'),
	},
	{
		path: '/:locale/fale-conosco',
		name: 'FaleConosco',
		component: () => import(/* webpackChunkName: "FaleConosco" */ '@views/FaleConosco/FaleConosco'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
	{
		path: '/resultados-da-busca',
		name: 'Busca',
		component: () => import(/* webpackChunkName: "Busca" */ '@views/Busca/Busca'),
	},
	{
		path: '/:locale/noticias/:slug',
		name: 'Noticia',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
	{
		path: '/:locale/:category/:subcategory?/:slug?',
		name: 'Pagina',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
	{
		path: '/alimentos/produtos',
		redirect: '/alimentos',
	},
]

export default routes
